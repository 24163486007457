import { Header } from "../components/header";
import LogoImg from "../assets/image/logoWh.png";
import CarImg from "../assets/image/car.png";
import BookedCarImg from "../assets/image/bookedCar.png";
import {
  DropDownField,
  GetInputField,
  NewDateTimePicker,
} from "../components/formDesign";
import { Footer } from "../components/footer";
import {
  createBooking,
  getBookingDetails,
  updateBooking,
} from "../api/booking";
import { useEffect, useState } from "react";
import { SuccessPage } from "./success";
import { useParams } from "react-router-dom";
import { ModelOptions } from "../components/options";
import { ServiceSuccessPage } from "./serviceSuccess";
import {
  LanFields,
  LanMainTitle,
  MainMessage,
  notesContent,
} from "../data/home";
import { useNavigate } from "react-router-dom";

let ryui = {
  EN: "Multi-Brand Service and Body Shop",
  AR: "خدمة متعددة العلامات التجارية وورشة تصليح",
};

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl mx-auto w-full";

export const BookingPage = ({ fields, serviceType, languageType }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    gsm: "",
    whatsapp: "",
    email: "",
    registration_number: "",
    brand: "",
    model: "",
    service_type: "",
    preferred_location: "Athaiba",
    preferred_date: "",
    notes: "",
    franchise: "sayarti-mb",
    language_selected: languageType
      ? languageType === "EN"
        ? "english"
        : "arabic"
      : "english",
  });

  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(languageType ? languageType : "EN");
  const [btnDisable, setBtnDisable] = useState(false);
  const [newData, setNewData] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [successPage, setSuccessPage] = useState(false);

  useEffect(() => {
    if (serviceType === "service") {
      fetchService();
    }
  }, [serviceType]);

  const fetchService = () => {
    getBookingDetails(id)
      .then(({ result }) => {
        setLoader(false);
        setData(result);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "brand" && data?.brand !== e.target.value) {
      setData((preState) => ({
        ...preState,
        [e.target.name]: e.target.value,
        model: "",
      }));
    } else {
      // if (e?.target?.name === "brand" && e?.target?.value === "Others") {
      //   setData((preState) => ({
      //     ...preState,
      //     [e.target.name]: e.target.value,
      //     model: "Others",
      //   }));
      // } else {
      setData((preState) => ({
        ...preState,
        [e.target.name]: e.target.value,
      }));
      // }
    }
  };

  const onSubmit = () => {
    setBtnDisable(true);
    if (data?.status === "Confirmed") {
      setBtnDisable(false);
      setError("Already Booked");
    } else {
      let inidata =
        serviceType === "service"
          ? {
              rep_name: data?.rep_name,
              rep_gsm: data?.rep_gsm,
              service_location: "Athaiba",
              service_date: data?.preferred_date,
              registration_number: data?.registration_number,
            }
          : {
              ...data,
              language_selected: language === "EN" ? "english" : "arabic",
            };
      let method = serviceType === "service" ? updateBooking : createBooking;
      method(inidata, id)
        .then(({ result }) => {
          setNewData(result);
          setBtnDisable(false);
          // setSuccessPage(true);
          if (serviceType === "booking") {
            navigate("/en/service-booking/thankyou");
          } else {
            navigate(`/service-booking/${result?.id}/thankyou`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let rt = fields.map((arr) => {
    if (arr?.name === "model") {
      return {
        ...arr,
        options: data?.brand
          ? ModelOptions.find((arrs) => arrs.brand === data?.brand).models
          : [],
      };
    } else {
      return arr;
    }
  });

  if (serviceType === "service" && loader) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div class="relative inline-block h-[40px] w-[40px] animate-spin">
          <div class="absolute left-[50%] h-full w-[25%] rotate-[120deg] transform">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
          <div class="absolute left-[50%] h-full w-[25%] rotate-[-120deg] transform">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
          <div class="absolute left-[50%] h-full w-[25%]">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <main className="overflow-x-hidden flex flex-col justify-between h-screen">
        <Header
          language={language}
          text={
            serviceType === "booking"
              ? LanMainTitle.sub_title[language]
              : "Confirmation Details"
          }
        />
        <section className={`${container} mb-20 p-4`}>
          {successPage ? (
            <>
              {serviceType === "service" ? (
                <ServiceSuccessPage data={newData} />
              ) : (
                <SuccessPage language={language} />
              )}
            </>
          ) : (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <div className="mt-0 md:mt-8 flex flex-col w-full items-center">
                  <div
                    className={`w-full flex justify-center ${
                      serviceType !== "booking"
                        ? "md:justify-center"
                        : "md:justify-between"
                    }  gap-5 flex-wrap ${
                      language === "AR" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    {serviceType === "booking" ? (
                      <strong
                        className={`flex flex-col  gap-6 w-[68%] ${
                          language === "AR" ? "items-start" : "items-end"
                        }`}
                      >
                        <div className="flex flex-col items-center gap-4">
                          <div className="w-[6rem] md:w-[10rem]">
                            <img
                              src={LogoImg}
                              className="w-full h-full"
                              alt="Logo"
                            />
                          </div>
                          <p className="text-white text-lg md:text-2xl text-center">
                            {ryui[language]}
                          </p>
                        </div>
                      </strong>
                    ) : (
                      <strong className="flex flex-col items-center gap-6">
                        <div className="w-[6rem] md:w-[10rem]">
                          <img
                            src={LogoImg}
                            className="w-full h-full"
                            alt="Logo"
                          />
                        </div>
                        <p className="text-lg md:text-2xl text-white mb-6">
                          {ryui[language]}
                        </p>
                      </strong>
                    )}
                    <div className={`flex justify-between`}>
                      {serviceType === "booking" && (
                        <div className="relative flex w-28 h-10 md:w-32 md:h-12 bg-[#595959] rounded-md border-2 border-yellow-400">
                          <input
                            type="radio"
                            id="en"
                            name="language"
                            className="hidden"
                            checked={language === "EN"}
                            onChange={() => {
                              setLanguage("EN");
                              navigate("/en/service-booking");
                            }}
                          />
                          <label
                            htmlFor="en"
                            className={`flex-1 text-[12px] md:text-md flex items-center justify-center ${
                              language === "EN"
                                ? "text-[#000000]"
                                : "text-[#FEC722]"
                            } font-bold cursor-pointer`}
                            style={{ zIndex: 99 }}
                          >
                            EN
                          </label>

                          <input
                            type="radio"
                            id="ar"
                            name="language"
                            className="hidden"
                            checked={language === "AR"}
                            onChange={() => {
                              setLanguage("AR");
                              navigate("/ar/service-booking");
                            }}
                          />
                          <label
                            htmlFor="ar"
                            style={{ zIndex: 99 }}
                            className={`flex-1 flex items-center justify-center ${
                              language === "AR"
                                ? "text-[#000000]"
                                : "text-[#FEC722]"
                            } font-bold cursor-pointer`}
                          >
                            AR
                          </label>

                          <div
                            className={`absolute top-0 left-0 w-1/2 h-full bg-yellow-400 rounded-md transition-transform duration-300 ${
                              language === "AR"
                                ? "translate-x-full"
                                : "translate-x-0"
                            }`}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-[40%]">
                    <img
                      src={serviceType === "service" ? BookedCarImg : CarImg}
                      className="w-full h-full mt-4"
                      alt="Logo"
                    />
                  </div>
                </div>
                <section
                  dir={language === "AR" && "rtl"}
                  className="bg-[#FEC722] p-2 w-full rounded-xl mt-4 md:mt-8"
                >
                  <div className="bg-[#FFF] p-3 w-full rounded-xl text-center px-10 relative">
                    <strong className="text-3xl">
                      {LanMainTitle.title[language]}
                    </strong>
                    {serviceType === "booking" ? (
                      <p className="mt-4 text-xl">{MainMessage[language]}</p>
                    ) : (
                      <p className="mt-4 text-xl">
                        Enter the details of the service representative
                        designated for{" "}
                        <span>
                          <strong>{data?.name}</strong>
                        </span>{" "}
                        service booking request.
                      </p>
                    )}
                    <div className="mt-4 text-start flex flex-wrap gap-8 justify-center  xl:justify-between">
                      {rt.map((arr) => {
                        if (arr.type === "date") {
                          return (
                            <NewDateTimePicker
                              openProps={{ open, setOpen }}
                              startDate={new Date()}
                              label={LanFields[arr?.name][language]}
                              handleChange={handleChange}
                              name={arr?.name}
                              value={data[arr?.name]}
                              required={arr?.required}
                              placeholder={
                                LanFields[arr?.name].placeholder[language]
                              }
                              icon={arr?.icon}
                            />
                          );
                        } else if (arr?.type === "dropDown") {
                          return (
                            <div>
                              <DropDownField
                                option={
                                  arr?.options
                                    ? arr?.options.map((arrs) => {
                                        return { label: arrs, value: arrs };
                                      })
                                    : []
                                }
                                value={data[arr?.name]}
                                label={LanFields[arr?.name][language]}
                                placeholder={
                                  LanFields[arr?.name]?.placeholder[language]
                                }
                                handleChange={handleChange}
                                name={arr?.name}
                                required={arr?.required}
                                icon={arr?.icon}
                              />
                            </div>
                          );
                        } else {
                          console.log(LanFields[arr?.name], "LanFields");
                          return (
                            <GetInputField
                              placeholder={
                                LanFields[arr?.name]?.placeholder[language]
                              }
                              label={LanFields?.[arr?.name]?.[language] || ""}
                              name={arr?.name}
                              handleChange={handleChange}
                              value={data[arr?.name]}
                              required={arr?.required}
                            />
                          );
                        }
                      })}
                      {serviceType === "booking" && (
                        <div className="text-start mt-4 w-full">
                          <GetInputField
                            placeholder={LanFields?.help?.placeholder[language]}
                            type="textarea"
                            label={LanFields.help[language]}
                            handleChange={handleChange}
                            name={"notes"}
                            value={data?.notes}
                          />
                        </div>
                      )}
                    </div>
                    {serviceType === "booking" && (
                      <div className="text-start mt-4 text-lg">
                        {/* <span className="text-[red]">Please note : </span>
                        By providing your contact information, you agree that we
                        may use your data to contact you with future marketing
                        and promotional campaigns. We value your privacy and
                        will not share your information with third parties
                        without your consent. */}
                        {notesContent[language]}
                      </div>
                    )}
                    <div>
                      <button
                        disabled={btnDisable}
                        className={`bg-[#FEC722] w-full p-4 my-6 pointer rounded-md ${
                          btnDisable ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                      >
                        {serviceType === "booking" ? (
                          <>{LanFields.continue[language]}</>
                        ) : (
                          "Confirm Service"
                        )}
                      </button>
                      {error && (
                        <h2 className="text-lg text-red">{error}....!!!!</h2>
                      )}
                    </div>
                  </div>
                </section>
              </form>
            </>
          )}
        </section>
        <Footer />
      </main>
    );
  }
};
