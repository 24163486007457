import React, { useEffect } from "react";
// import uploader from "assets/media/svg/uploader.svg";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { Calendar } from "react-date-range";
// import { titleize } from "helper";
// import Spin from "assets/media/svg/loading.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";

export const GetInputField = ({
  icon,
  placeholder,
  type = "text",
  value,
  name,
  handleChange,
  label,
  onClick,
  required,
  top,
}) => {
  return (
    <div className="relative">
      {icon && (
        <div
          style={{ top: top, backgroundColor: "#F0F0F0", padding: "16px 10px" }}
          className={`absolute right-4`}
        >
          {icon}
        </div>
      )}
      {label && <p className="text-lg">{!required ? label : `${label} *`}</p>}
      {type === "text" ? (
        <input
          className="bg-[#F0F0F0] w-[14rem] md:w-[20rem]  h-[60px] pl-4 p-4 outline-none text-[18px] rounded-lg"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          onClick={onClick}
          required={required}
        />
      ) : (
        <textarea
          className="bg-[#F0F0F0] w-full rounded-lg pl-5 p-4 leading-relaxed text-[18px] outline-none h-[180px] resize-none"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export const InputText = ({
  placeholder,
  className,
  handleChange,
  name,
  value,
  type = "text",
}) => {
  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      className={
        "outline-none border-[0.5px] border-solid border-[#BABABA] p-1 rounded bg-[#FFFCFC] " +
        className
      }
      onChange={handleChange}
    />
  );
};

export const CheckBox = ({ className }) => {
  return <input type="checkbox" className={className} />;
};

export const LoginButton = ({ title, onclick, disable, btnLoader }) => {
  return (
    <button
      className={`w-full rounded py-1.5 font-semibold flex justify-center gap-3 bg-[#FFC717] text-white`}
      onClick={onclick}
      disabled={disable}
      style={{ cursor: disable ? "not-allowed" : "pointer" }}
    >
      {/* {btnLoader && (
        <img src={Spin} className="animate-spin" height="20px" width="20px" />
      )} */}
      {title}
    </button>
  );
};

export const DropDownField = ({
  style,
  option,
  name,
  handleChange,
  langKeyValue,
  label,
  value,
  placeholder,
  required,
  icon,
}) => {
  return (
    <div className="relative">
      {label && <p className="text-lg">{!required ? label : `${label} *`}</p>}

      {/* <GetInputField
        readOnly
        type="text"
        value={value}
        placeholder={placeholder}
        label={label}
        icon={icon}
        top={"42%"}
        required={required}
        className={
          `rounded border-[#BABABA] cursor-pointer border-[1px] px-1 outline-none common ${
            langKeyValue === "en"
              ? "bg-[left_97%_center]"
              : "bg-[right_97%_center]"
          } ` + style
        }
      /> */}
      <ReactSelect
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        // isClearable={isClearable}
        // isRtl={isRtl}
        // isSearchable={isSearchable}
        placeholder={placeholder}
        name={name}
        options={option}
        value={value ? { label: value, value: value } : value}
        onChange={(e) => {
          handleChange({
            target: {
              name,
              value: e.value,
            },
          });
        }}
        className={
          "bg-[#F0F0F0] w-[14rem] md:w-[20rem] h-[60px] p-2 outline-none text-[18px] rounded-lg basic-single rounded absolute top-0"
        }
      />
      {/* <select
        className={
          "rounded bg-white border-[#BABABA] border-[1px] px-1 common opacity-0 absolute top-0 w-full h-full"
        }
        name={name}
        onClick={(e) => {
          handleChange({
            target: {
              name,
              value: e.target.value,
            },
          });
        }}
      >
        {option.map((value, index) => {
          return (
            <option value={value.value} key={index}>
              {value.label}
            </option>
          );
        })}
      </select> */}
    </div>
  );
};

export const DateSelector = ({
  handleDateChange,
  name,
  startDate,
  endDate,
  style,
  openDate,
  handleClick,
  langKeyValue,
}) => {
  useEffect(() => {
    const rmClose = document.getElementsByClassName("close")[0];
    rmClose?.remove();
    const close = document.createElement("div");
    close.innerHTML = "OK";
    close.className = "close";
    close.onclick = () => {
      handleClick(openDate?.startDate ? "startDate" : "endDate");
    };
    const closeBtn =
      document.getElementsByClassName("rdrCalendarWrapper")[
        openDate?.startDate ? 0 : 1
      ];
    closeBtn?.appendChild(close);
  });
  return (
    <div className="relative">
      <input
        onClick={() => handleClick(name)}
        readOnly
        className={
          `outline-none border-[1px] border-[#BABABA] rounded px-2 w-full min-w-[90%] inputDate ${
            langKeyValue === "en"
              ? "bg-[right_10px_center]"
              : "bg-[left_10px_center]"
          } ` + style
        }
        placeholder="dd/mm/yyyy"
        value={
          name === "startDate"
            ? format(new Date(), "dd/MM/yyyy")
            : format(new Date(), "dd/MM/yyyy")
        }
      />
      <DateRangePicker
        onChange={(e) => {
          handleDateChange({
            target: {
              startDate: e?.[name].startDate,
              endDate: e?.[name].endDate,
            },
          });
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        months={2}
        scroll={{ enabled: false }}
        minDate={new Date()}
        ranges={[{ startDate, endDate, key: name }]}
        direction="horizontal"
      />
    </div>
  );
};

export const SingleDate = ({
  style,
  value,
  handleChange,
  openProps,
  label,
  name,
  required,
  placeholder,
  icon,
}) => {
  const { open, setOpen } = openProps;

  return (
    <div className="relative">
      {/* {label && <p className="text-lg">{label}</p>} */}
      <GetInputField
        onClick={() => {
          setOpen(!open);
        }}
        className={
          "outline-none border-[1px] border-[#BABABA] rounded px-2 w-full " +
          style
        }
        top={"32%"}
        placeholder={placeholder}
        value={value ? format(value || new Date(), "dd/MM/yyyy") : null}
        required={required}
        label={label}
        icon={icon}
      />
      <Calendar
        onChange={(e) => {
          handleChange({
            target: {
              name: name,
              value: e,
            },
          });
        }}
        className={`${
          open ? "block" : "hidden"
        } absolute top-[80px] left-0 bg-[#f9f9f9] single-date`}
        date={value}
      />
    </div>
  );
};

export const TextField = ({ placeholder, name, value, onHandleChange }) => {
  return (
    <>
      <p className="text-[16px] text-[#767676] font-medium pb-[0.5rem]">
        {placeholder}
      </p>
      <input
        className="bg-[#F9F9F9] border-[1px] border-[#BABABA] border-solid rounded p-2 w-full outline-none"
        name={name}
        value={value}
        onChange={(e) => {
          onHandleChange({
            target: {
              name,
              value: e.target.value,
            },
          });
        }}
      />
    </>
  );
};

// export const DragDrop = () => {
//   return (
//     <div className="relative border-dashed py-6 px-8 border-2">
//       <div className="flex gap-10">
//         <div>
//           <img src={uploader} />
//         </div>
//         <div className="font-['Quicksand']">
//           <h1 className="text-[#BDBDBD] text-[20px] font-bold">Upload Image</h1>
//           <h2 className="text-[#4F4F4F] text-[16px] font-light">
//             Support .jpg or .png upto 3MB
//           </h2>
//         </div>
//       </div>
//       <input type="file" accept="png" className="cursor-pointer" />
//     </div>
//   );
// };

export const SideDrawer = ({
  handleSideDrawer,
  // handleCurrentLocation,
  location,
  style,
}) => {
  return (
    <div
      className={
        "border-[1px] border-[#BABABA] border-solid rounded px-2 flex items-center justify-between gap-[6px] w-full " +
        style
      }
    >
      <p
        className="whitespace-nowrap overflow-hidden flex-1 cursor-pointer"
        onClick={handleSideDrawer}
      >
        {location ? location : "Select Location"}
      </p>
      {/* <img
        className="w-[18px] h-[18px] object-scale-down cursor-pointer"
        src="https://docs-assets.katomaran.tech/images/sayarti/2022/10/current_location.png"
        onClick={handleCurrentLocation}
      /> */}
    </div>
  );
};

export const SideDrawerDrop = ({
  handledropSideDrawer,
  // handleCurrentLocationDrop,
  dropLocation,
  style,
}) => {
  return (
    <div
      className={
        "border-[1px] border-[#BABABA] border-solid rounded px-2 flex items-center justify-between gap-[6px] w-full " +
        style
      }
    >
      <p
        className="whitespace-nowrap overflow-hidden flex-1 cursor-pointer"
        onClick={handledropSideDrawer}
      >
        {dropLocation ? dropLocation : "Select Location"}
      </p>
      {/* <img
        className="w-[18px] h-[18px] object-scale-down cursor-pointer"
        src="https://docs-assets.katomaran.tech/images/sayarti/2022/10/current_location.png"
        onClick={handleCurrentLocationDrop}
      /> */}
    </div>
  );
};

// export const DeleteButton = ({ deleteValue, onclick, name }) => {
//   return (
//     <a
//       className={`delete-a-tag ${deleteValue === name && "active"}`}
//       name={name}
//       onClick={(e) => {
//         onclick(e);
//       }}
//       href="#"
//     >
//       <span />
//       <text className="absolute right-[5px] text-[#FF3E1D] duration-[0.5s] font-semibold">
//         Delete
//       </text>
//     </a>
//   );
// };

export const NewDateTimePicker = ({
  startDate,
  handleChange,
  height,
  style,
  value,
  openProps,
  label,
  name,
  required,
  placeholder,
  icon,
}) => {
  return (
    <div>
      {label && <p className="text-lg">{!required ? label : `${label} *`}</p>}

      <div>
        <DatePicker
          showTimeSelect
          onChange={(e) =>
            handleChange({
              target: {
                name: name,
                value: e,
              },
            })
          }
          placeholderText={placeholder}
          selected={value ? new Date(value) : value}
          dateFormat="dd/MM/yy hh:mm a"
          timeFormat="hh:mm a"
          timeIntervals={30}
          className={`bg-[#F0F0F0] w-[14rem] md:w-[20rem] h-[60px] pl-4 p-4 outline-none caret-white text-[18px] rounded-lg newdatepicker ${
            height && "heightClass"
          }`}
        />
      </div>
    </div>
  );
};

export const NewDateTimePickerEnd = ({
  endDate,
  handleDateTimeChange,
  height,
}) => {
  return (
    <DatePicker
      showTimeSelect
      onChange={(e) => handleDateTimeChange("endDate", e)}
      selected={endDate}
      dateFormat="dd/MM/yy hh:mm a"
      timeFormat="hh:mm a"
      timeIntervals={30}
      className={`newdatepicker  w-full ${height && "heightClass"}`}
    />
  );
};
