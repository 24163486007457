import makeRequest from ".";

export function createBooking(body) {
  return makeRequest({
    uri: `/service-booking`,
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function updateBooking(body, id) {
  return makeRequest({
    uri: `/service-booking/${id}/update`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}

export function getBookingDetails(id) {
  return makeRequest({
    uri: `/service-booking/${id}`,
  });
}
