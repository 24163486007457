import Logo from "../assets/image/logoWh.png";
import phoneImg from "../assets/image/phone.svg";
import instaImg from "../assets/image/insta.svg";
import faceBookImg from "../assets/image/facebook.svg";
import LinkedInImg from "../assets/image/linkedin.svg";
import mailImg from "../assets/image/mail.svg";
import QrImg from "../assets/image/QR.png";

export const Footer = () => {
  return (
    <div className="relative bg-[#292929] p-4">
      <div className="absolute top-[-25px] left-0 right-0 h-6 bg-checkered"></div>

      <div className="relative bg-[#292929] rounded-md p-4 flex items-center justify-center md:justify-between flex-wrap gap-10">
        <div className="flex items-center flex-col gap-2">
          <img src={Logo} alt="Sayarti Logo" className="h-12" />
          <div className="flex gap-4">
            <a
              href="https://www.facebook.com/profile.php?id=61565232625844"
              target="_blank"
              rel="noreferrer"
            >
              <img src={faceBookImg} alt="facebook" className="h-10 w-6" />
            </a>
            <a
              href="https://www.linkedin.com/company/sayarti-oman/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedInImg} alt="linked" className="h-10 w-6" />
            </a>
            <a
              href="https://www.instagram.com/sayarti_om/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instaImg} alt="insta" className="h-10 w-6" />
            </a>
          </div>
        </div>

        <div className="text-white">
          <div className="flex items-center mt-2 gap-2">
            <img src={phoneImg} alt="phone" className="h-10 w-6" />

            <a href="tel:24500555">24 500 555</a>
          </div>
          <div className="flex items-center mt-2 gap-2">
            <img src={mailImg} alt="phone" className="h-10 w-6" />

            <a href="mailto:multibrand@sayarti.com">multibrand@sayarti.com</a>
          </div>
          {/* <div className="flex items-center mt-2 gap-2">
            <img src={instaImg} alt="phone" className="h-10 w-6" />

            <p>@sayartioman</p>
          </div> */}
        </div>

        <div className="flex items-center flex-wrap justify-center gap-2 md:justify-between">
          <div className="text-white mr-4 text-center">
            <a
              href="https://maps.app.goo.gl/4kEhDPEBMjp81MUK7"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
            >
              <div className="flex justify-center">
                <div className="bg-yellow-400 p-2 rounded-md text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                      <path
                        fill="currentColor"
                        d="M12 2a9 9 0 0 1 9 9c0 3.074-1.676 5.59-3.442 7.395a20.4 20.4 0 0 1-2.876 2.416l-.426.29l-.2.133l-.377.24l-.336.205l-.416.242a1.87 1.87 0 0 1-1.854 0l-.416-.242l-.52-.32l-.192-.125l-.41-.273a20.6 20.6 0 0 1-3.093-2.566C4.676 16.589 3 14.074 3 11a9 9 0 0 1 9-9m0 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <p className="text-xs mt-2 w-20">
                CLICK OR SCAN THE QR CODE FOR LOCATION
              </p>
            </a>
          </div>
          <div className="flex flex-col items-center text-white">
            <a
              href="https://maps.app.goo.gl/4kEhDPEBMjp81MUK7"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
            >
              <img src={QrImg} alt="QR Code" className="h-24 w-24" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
