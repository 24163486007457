import tickImg from "../assets/image/tick.png";
// import DownAppleImg from "../assets/image/downloadApple.png";
// import DownPlayImg from "../assets/image/downloadPlay.png";
import phoneImg from "../assets/image/phone.svg";
import mailImg from "../assets/image/mail.svg";
import { SuccessContent } from "../data/success";

export const SuccessPage = ({ language }) => {
  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="w-[8rem]">
          <img src={tickImg} className="w-full h-full mt-16" alt="Logo" />
        </div>
      </div>
      <section className="p-2 w-full rounded-xl mt-8 flex  text-white">
        <div className="p-3 w-full rounded-xl text-center  flex flex-col items-center">
          <h4 className="text-xl md:text-2xl mb-4">
            {SuccessContent.title[language]}
          </h4>

          <p className="mt-4 text-xl md:text-2xl mb-4">
            {SuccessContent.sub_title[language]}
          </p>

          <p className="mt-4 text-xl md:text-2xl mb-4">
            {SuccessContent.sub_title2[language]}
          </p>
          <div className="flex flex-col items-center my-8 mt-2 p-3">
            <div className="flex items-center mt-2 gap-2 flex-wrap justify-center  md:justify-between">
              <img src={mailImg} alt="phone" className="h-10 w-8" />
              <a href="mailto:multibrand@sayarti.com">
                <h4 className="text-xl md:text-2xl text-[#FEC722]">
                  multibrand@sayarti.com
                </h4>
              </a>
            </div>
            <div className="flex items-center mt-2 gap-2 flex-wrap">
              <img src={phoneImg} alt="phone" className="h-10 w-8" />
              <a href="tel:24500555">
                <h4 className="text-xl md:text-2xl text-[#FEC722]">
                  24 500 555
                </h4>
              </a>
            </div>
          </div>
          {/* <h3 className="text-xl md:text-2xl">For all our online services</h3> */}
          {/* <div className="mb-2 flex flex-wrap justify-center">
            <div className="h-15 md:h-20 mt-4">
              <a href="https://play.google.com/store/apps/details?id=com.zubair.android&hl=en&pli=1" target="_blank" rel="noreferrer"  >
                <img src={DownPlayImg} className="w-full h-full" alt="Logo" />
              </a>
            </div>
            <div className="h-15 md:h-20 mt-4">
              <a href="https://apps.apple.com/om/app/gac-oman/id1453015915" target="_blank" rel="noreferrer" >
                <img src={DownAppleImg} className="w-full h-full" alt="Logo" />
              </a>
            </div>
          </div>
          <h3 className="text-xl md:text-2xl">
            Download Zubair Automotive App
          </h3> */}
        </div>
      </section>
    </>
  );
};
