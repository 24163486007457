export const BrandOptions = [
  "Toyota",
  "Nissan",
  "Honda",
  "Mazda",
  "Lexus",
  "Infiniti",
  "Hyundai",
  "Others",
];

export const serviceType = [
  "Periodic Maintenance Service with Quick Repairs",
  "Battery Check and Replacement",
  "Smart Repair and Accident Repair",
  "Brake Related Jobs",
  "Tire Replacement with Wheel Balancing and Alignment",
  "Exterior and Interior Detailing",
  "Quick A/C Check Up and Gas Top-Up",
  "Window Tinting (LLUMAR)",
  "Vehicle Diagnosis and Pre-Purchase Inspection",
  "Paint + Rust Protection and Ceramic Coating",
  "10,000 KM",
  "20,000 KM",
  "30,000 KM",
  "40,000 KM",
  "50,000 KM",
  "60,000 KM",
  "70,000 KM",
  "80,000 KM",
  "90,000 KM",
  "100,000 KM",
  "100,000+ KM",
  "Others"
];

export const ModelOptions = [
  {
    brand: "Toyota",
    models: [
      "Toyota Corolla",
      "Toyota Camry",
      "Toyota Land Cruiser",
      "Toyota Land Cruiser Prado",
      "Toyota Avalon",
      "Toyota FJ Cruiser",
      "Toyota RAV4",
      "Toyota Yaris Sedan",
      "Toyota Fortuner",
      "Toyota Hilux",
      "Toyota Yaris",
      "Toyota Land Cruiser Pick-Up",
      "Toyota Hiace",
      "Toyota Rush",
      "Others",
    ],
  },
  {
    brand: "Nissan",
    models: [
      "Nissan Sunny",
      "Nissan Altima",
      "Nissan Maxima",
      "Nissan Kicks",
      "Nissan X-Trail",
      "Nissan Pathfinder",
      "Nissan Patrol",
      "Nissan Patrol Nismo",
      "Nissan Patrol Safari",
      "Nissan Patrol Pickup",
      "Nissan Xterra",
      "Nissan GT-R",
      "Nissan Urvan",
      "Others",
    ],
  },
  {
    brand: "Honda",
    models: [
      "Honda Civic",
      "Honda Accord",
      "Honda CR-V",
      "Honda City",
      "Honda HR-V",
      "Honda ZR-V",
      "Honda Civic Type R",
      "Others",
    ],
  },
  {
    brand: "Mazda",
    models: [
      "Mazda 3",
      "Mazda 6",
      "Mazda CX-5",
      "Mazda 2",
      "Mazda CX-3",
      "Mazda CX-30",
      "Mazda CX-5",
      "Mazda CX-60",
      "Mazda CX-9",
      "Mazda CX-90",
      "Mazda MX-5",
      "Mazda pick up",
      "Mazda BT-50",
      "Others",
    ],
  },
  {
    brand: "Lexus",
    models: [
      "Lexus IS",
      "Lexus ES",
      "Lexus LS",
      "Lexus UX",
      "Lexus NX",
      "Lexus RX",
      "Lexus GX",
      "Lexus LX",
      "Lexus RC",
      "Lexus LC",
      "Lexus ES Hybrid",
      "Lexus RX Hybrid",
      "Lexus NX Hybrid",
      "Lexus LS Hybrid",
      "Others",
    ],
  },
  {
    brand: "Infiniti",
    models: [
      "Infiniti Q50",
      "Infiniti Q60",
      "Infiniti Q70",
      "Infiniti Q50 Hybrid",
      "Infiniti QX30",
      "Infiniti QX50",
      "Infiniti QX60",
      "Infiniti QX70",
      "Infiniti QX80",
      "Others",
    ],
  },
  {
    brand: "Hyundai",
    models: [
      "Hyundai Accent",
      "Hyundai Elantra",
      "Hyundai Sonata",
      "Hyundai Azera",
      "Hyundai Veloster",
      "Hyundai Kona",
      "Hyundai Tucson",
      "Hyundai Santa Fe",
      "Hyundai Palisade",
      "Hyundai Creta",
      "Hyundai i10",
      "Hyundai i20",
      "Hyundai H1",
      "Hyundai H-100",
      "Others",
    ],
  },
  {
    brand: "Kia",
    models: [
      "Kia Picanto",
      "Kia Rio",
      "Kia Cerato",
      "Kia Optima",
      "Kia Cadenza",
      "Kia Stinger",
      "Kia Soul",
      "Kia Seltos",
      "Kia Sportage",
      "Kia Sorento",
      "Kia Telluride",
      "Kia Mohave",
      "Kia Carnival",
      "Kia K900",
      "Others",
    ],
  },
  {
    brand: "Genesis",
    models: [
      "Genesis G70",
      "Genesis G80",
      "Genesis G90",
      "Genesis GV70",
      "Genesis GV80",
      "Others",
    ],
  },
  {
    brand: "Geely",
    models: [
      "Geely Emgrand 7",
      "Geely Emgrand GS",
      "Geely Emgrand X7 Sport",
      "Geely Coolray",
      "Geely Azkarra",
      "Geely Tugella/pr",
      "Others",
    ],
  },
  {
    brand: "MG",
    models: ["MG 5", "MG 6", "MG ZS", "MG RX5", "MG HS", "MG RX8", "Others"],
  },
  {
    brand: "JAC",
    models: ["JAC GS3", "JAC GS4", "JAC GS5", "JAC GS8", "JAC GN8", "Others"],
  },
  {
    brand: "Subaru",
    models: [
      "Subaru Forester",
      "Subaru Impreza",
      "Subaru Outback",
      "Subaru WRX",
      "Subaru WRX STI",
      "Subaru XV (Crosstrek)",
      "Subaru Ascent",
      "Subaru BRZ",
      "Others",
    ],
  },
  {
    brand: "Suzuki",
    models: [
      "Suzuki Swift",
      "Suzuki Vitara",
      "Suzuki Jimny",
      "Suzuki Grand Vitara",
      "Suzuki Dzire",
      "Suzuki Ignis",
      "Suzuki Celerio",
      "Suzuki S-Presso",
      "Others",
    ],
  },
  {
    brand: "Others",
    models: ["Others"],
  },
];

export const BookingFields = [
  {
    label: "Name",
    placeholder: "Enter your name",
    name: "name",
    required: true,
  },
  {
    label: "Email ID",
    placeholder: "Enter your email id",
    name: "email",
    required: true,
  },
  {
    label: "Mobile No.",
    placeholder: "Enter mobile number",
    name: "gsm",
    required: true,
  },
  {
    label: "WhatsApp No.",
    placeholder: "Enter whatsapp number",
    name: "whatsapp",
  },
  {
    label: "Preferred Date",
    placeholder: "Enter Preferred Date",
    type: "date",
    name: "preferred_date",
    required: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="1.5em"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path
            stroke="currentColor"
            stroke-width="1.5"
            d="M2 12c0-3.771 0-5.657 1.172-6.828S6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172S22 8.229 22 12v2c0 3.771 0 5.657-1.172 6.828S17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172S2 17.771 2 14z"
          />
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.5"
            d="M7 4V2.5M17 4V2.5M2.5 9h19"
          />
          <path
            fill="currentColor"
            d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0"
          />
        </g>
      </svg>
    ),
  },
  {
    label: "Service Type",
    placeholder: "Select Service type",
    type: "dropDown",
    name: "service_type",
    required: true,
    options: serviceType,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="1em"
        viewBox="0 0 16 7"
      >
        <path
          fill="currentColor"
          d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"
        />
      </svg>
    ),
  },
  {
    label: "Brand",
    placeholder: "Select Brand",
    type: "dropDown",
    name: "brand",
    required: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="1em"
        viewBox="0 0 16 7"
      >
        <path
          fill="currentColor"
          d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"
        />
      </svg>
    ),
    options: BrandOptions,
  },
  {
    label: "Model",
    placeholder: "Select Model",
    type: "dropDown",
    name: "model",
    required: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2em"
        height="1em"
        viewBox="0 0 16 7"
      >
        <path
          fill="currentColor"
          d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"
        />
      </svg>
    ),
  },
  {
    label: "Vehicle Registration No.",
    placeholder: "Enter vehicle reg Number",
    name: "registration_number",
    required: true,
  },
];

export const serviceFields = [
  {
    label: "Name",
    placeholder: "Enter your name",
    name: "rep_name",
    required: true,
  },
  {
    label: "GSM No.",
    placeholder: "Enter GSM Number",
    name: "rep_gsm",
    required: true,
  },
  {
    label: "Service Date",
    placeholder: "Enter Service Date",
    type: "date",
    name: "preferred_date",
    required: true,
  },
  {
    label: "Registration Number",
    placeholder: "Enter reg Number",
    name: "registration_number",
    required: true,
  },
];
