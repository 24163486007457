import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { getBookingDetails } from "../api/booking";
import { useEffect, useState } from "react";
import { SuccessPage } from "./success";
import { useParams } from "react-router-dom";
import { ServiceSuccessPage } from "./serviceSuccess";
import { LanMainTitle } from "../data/home";

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl mx-auto w-full";

export const BookingSuccessPage = ({ fields, serviceType, languageType }) => {
  const { id } = useParams();

  const [newData, setNewData] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (serviceType === "service") {
      fetchService();
    }
  }, [serviceType]);

  const fetchService = () => {
    getBookingDetails(id)
      .then(({ result }) => {
        setLoader(false);
        setNewData(result);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  if (serviceType === "service" && loader) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div class="relative inline-block h-[40px] w-[40px] animate-spin">
          <div class="absolute left-[50%] h-full w-[25%] rotate-[120deg] transform">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
          <div class="absolute left-[50%] h-full w-[25%] rotate-[-120deg] transform">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
          <div class="absolute left-[50%] h-full w-[25%]">
            <div class="absolute top-0 left-0 w-full pb-[100%] bg-[#FEC722] rounded-full animate-wobble"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <main className="overflow-x-hidden flex flex-col justify-between h-screen">
        <Header
          language={languageType}
          text={
            serviceType === "booking"
              ? LanMainTitle.sub_title[languageType]
              : "Confirmation Details"
          }
        />
        <section className={`${container} mb-20 p-4`}>
          {serviceType === "service" ? (
            <ServiceSuccessPage data={newData} />
          ) : (
            <SuccessPage language={languageType} />
          )}
        </section>
        <Footer />
      </main>
    );
  }
};
