import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { getBookingDetails } from "../api/booking";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import tickImg from "../assets/image/badge.png";
// import DownAppleImg from "../assets/image/downloadApple.png";
// import DownPlayImg from "../assets/image/downloadPlay.png";
import phoneImg from "../assets/image/phone.svg";
import mailImg from "../assets/image/mail.svg";
import moment from "moment";
import { content, details, title } from "../data/details";
import { useNavigate } from "react-router-dom";

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl mx-auto";

export const ConfirmBooking = ({ languageType }) => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(languageType);

  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = () => {
    getBookingDetails(id)
      .then(({ result }) => {
        console.log(result);
        setData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className="overflow-x-hidden flex flex-col justify-between h-screen text-white">
      <Header />
      <div className="flex flex-col w-full items-center">
        <div className="p-3 w-full rounded-xl text-center px-10 flex flex-col items-center">
          <div
            className={`w-full flex ${
              language === "AR" ? "justify-start" : "justify-end"
            }`}
          >
            <div className="relative flex w-32 h-12 bg-[#595959] rounded-md border-2 border-yellow-400">
              <input
                type="radio"
                id="en"
                name="language"
                className="hidden"
                checked={language === "EN"}
                onChange={() => {
                  setLanguage("EN");
                  navigate(`/en/service-booking/${id}/success`);
                }}
              />
              <label
                htmlFor="en"
                className={`flex-1 flex items-center justify-center ${
                  language === "EN" ? "text-[#000000]" : "text-[#FEC722]"
                } font-bold cursor-pointer`}
                style={{ zIndex: 99 }}
              >
                EN
              </label>

              <input
                type="radio"
                id="ar"
                name="language"
                className="hidden"
                checked={language === "AR"}
                onChange={() => {
                  setLanguage("AR");
                  navigate(`/ar/service-booking/${id}/success`);
                }}
              />
              <label
                htmlFor="ar"
                style={{ zIndex: 99 }}
                className={`flex-1 flex items-center justify-center ${
                  language === "AR" ? "text-[#000000]" : "text-[#FEC722]"
                } font-bold cursor-pointer`}
              >
                AR
              </label>

              <div
                className={`absolute top-0 left-0 w-1/2 h-full bg-yellow-400 rounded-md transition-transform duration-300 ${
                  language === "AR" ? "translate-x-full" : "translate-x-0"
                }`}
              ></div>
            </div>
          </div>
          <div className="h-[8rem] my-10">
            <img src={tickImg} className="w-full h-full" alt="Logo" />
          </div>
          <h4 className="text-2xl md:text-4xl mb-4 font-bold">
            {title[languageType]}
          </h4>
          <>
            {content({
              name: data?.name,
              model: data?.brand,
              registration_number: data?.registration_number,
              service_type: data?.service_type,
              service_location: data?.service_location || "",
              service_date: moment(data?.preferred_date).format(
                "DD-MMM-YYYY hh:mm A"
              ),
              language: languageType,
            })}
          </>
          {/* <p className="text-xl md:text-2xl mt-4 mb-2 sm:w-[56%] w-[100%]">
            Dear{" "}
            <span className="text-[#FEC722] font-semibold">{data?.name}</span>,
            we are delighted to advise your service booking with Sayatri for
            your vehicle{" "}
            <span className="text-[#FEC722] font-semibold">
              {data?.brand} ( {data?.registration_number})
            </span>{" "}
            for {data?.service_type}
          </p> */}

          {/* <p className="mt-4 text-xl md:text-2xl mb-2">
            Service at {data?.service_location || ""} on{" "}
            <span className="text-[#FEC722] font-semibold">
              {moment(data?.preferred_date).format("DD-MMM-YYYY hh:mm A")}{" "}
            </span>
            is confirmed
          </p> */}
          <section className={`${container} mb-2 text-black`}>
            <section className="bg-[#FEC722] p-2 w-full rounded-xl mt-8">
              <div className="bg-[#FFF] p-3 w-full rounded-xl text-center sm:px-4 px-4">
                {/* <p className="mt-4 text-xl md:text-2xl">
                  Your designated Safe Check-In representative is
                </p>
                <p className="mt-4 text-xl md:text-2xl">
                  Name: <span className="font-semibold">{data?.rep_name}</span>{" "}
                </p>
                <p className="mt-4 text-xl md:text-2xl">
                  GSM No: <span className="font-semibold">{data?.rep_gsm}</span>
                </p>
                <p className="mt-4 text-xl md:text-2xl">
                  Please call this number on your arrival
                </p> */}
                {details({
                  rep_name: data?.rep_name,
                  rep_gsm: data?.rep_gsm,
                  language: languageType,
                })}
              </div>
            </section>
          </section>
          <div className="flex flex-col items-center my-8 mt-2 p-3">
            <div className="flex items-center mt-2 gap-2 flex-wrap justify-center  md:justify-between">
              <img src={mailImg} alt="phone" className="h-10 w-8" />
              <a href="mailto:multibrand@sayarti.com">
                <h4 className="text-xl md:text-2xl text-[#FEC722]">
                  multibrand@sayarti.com
                </h4>
              </a>
            </div>
            <div className="flex items-center mt-2 gap-2 flex-wrap">
              <img src={phoneImg} alt="phone" className="h-10 w-8" />
              <a href="tel:24500555">
                <h4 className="text-xl md:text-2xl text-[#FEC722]">
                  24 500 555
                </h4>
              </a>
            </div>
          </div>
          {/* <h3 className="text-xl md:text-2xl">For all our online services</h3>
          <div className="mb-2 flex flex-wrap justify-center">
            <div className="h-15 md:h-20 mt-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.zubair.android&hl=en&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={DownPlayImg} className="w-full h-full" alt="Logo" />
              </a>
            </div>
            <div className="h-15 md:h-20 mt-4">
              <a
                href="https://apps.apple.com/om/app/gac-oman/id1453015915"
                target="_blank"
                rel="noreferrer"
              >
                <img src={DownAppleImg} className="w-full h-full" alt="Logo" />
              </a>
            </div>
          </div>
          <h3 className="text-xl md:text-2xl">
            Download Zubair Automotive App
          </h3> */}
        </div>
        <section className="p-2 w-full rounded-xl mt-8 flex  text-white"></section>
      </div>
      <Footer />
    </main>
  );
};
