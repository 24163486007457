import tickImg from "../assets/image/tick.png";
import copy from "clipboard-copy";

export const ServiceSuccessPage = ({ data }) => {
  const handleCopy = () => {
    copy(data?.success_url || "");
  };

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="w-[8rem]">
          <img src={tickImg} className="w-full h-full mt-16" alt="Logo" />
        </div>
      </div>
      <section className="p-2 w-full rounded-xl mt-8 flex text-white">
        <div className="p-3 w-full rounded-xl text-center flex flex-col items-center">
          <h4 className="text-xl md:text-2xl mb-4">
            {data?.rep_name} is now the designated service represeritative for{" "}
            {data?.name} service booking request.
          </h4>

          <p className="mt-4 text-xl md:text-2xl mb-4">
            Customer's Confirmation Page URI
          </p>
          <div className="flex items-center gap-2">
            <div className="text-xl md:text-2xl border border-white p-2 rounded-md ">
              {data?.success_url}
            </div>
            <div className="cursor-pointer" onClick={handleCopy}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                className="text-xl md:text-4xl"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                >
                  <path d="M16 3H4v13" />
                  <path d="M8 7h12v12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
