export const LanMainTitle = {
  title: { EN: "Welcome !", AR: "هلًا بك!" },
  sub_title: {
    EN: "ONLINE SERVICE BOOKING",
    AR: "حجز الخدمة عن طريق الانترنت",
  },
};

export const MainMessage = {
  EN: "Dear Customer, Please complete the form below to make an online booking",
  AR: " عزيزنا العميل, الرجاء تكملة البيانات لحجز موعد الخدمة ",
};
export const LanFields = {
  name: {
    EN: "Name",
    AR: "الاسم",
    placeholder: {
      EN: "Enter your name",
      AR: "أدخل اسمك",
    },
  },
  rep_name: {
    EN: "Name",
    AR: "الاسم",
    placeholder: {
      EN: "Enter your name",
      AR: "أدخل اسمك",
    },
  },
  email: {
    EN: "Email",
    AR: " البريد الالكتروني",
    placeholder: {
      EN: "Enter your email id",
      AR: "أدخل بريدك الإلكتروني",
    },
  },
  gsm: {
    EN: "Mobile No",
    AR: "رقم الهاتف",
    placeholder: {
      EN: "Enter mobile number",
      AR: "أدخل رقم الجوال",
    },
  },
  rep_gsm: {
    EN: "Mobile No",
    AR: "رقم الهاتف",
    placeholder: {
      EN: "Enter mobile number",
      AR: "أدخل رقم الجوال",
    },
  },
  preferred_date: {
    EN: "Service Date",
    AR: "رقم الهاتف",
    placeholder: {
      EN: "Enter Service Date",
      AR: "أدخل رقم الجوال",
    },
  },
  whatsapp: {
    EN: "Whatsapp No",
    AR: "رقم الواتساب",
    placeholder: {
      EN: "Enter whatsapp number",
      AR: "أدخل رقم الواتساب",
    },
  },
  brand: {
    EN: "Brand",
    AR: "العلامة التجاريه",
    placeholder: {
      EN: "Select Brand",
      AR: "اختر نوع السيارة",
    },
  },
  model: {
    EN: "Model",
    AR: "الطراز",
    placeholder: {
      EN: "Select Model",
      AR: "اختر الطراز",
    },
  },
  location: {
    EN: "Location",
    AR: "الفرع",
    placeholder: {
      EN: "",
      AR: "",
    },
  },
  registration_number: {
    EN: "Vehicle Registration No",
    AR: "رقم تسجيل المركبة",
    placeholder: {
      EN: "Enter vehicle reg number",
      AR: "أدخل رقم تسجيل المركبة",
    },
  },
  preferred_date: {
    AR: "التاريخ المقترح",
    EN: "Preferred Date",
    placeholder: {
      EN: "Enter Preferred Date",
      AR: "أدخل التاريخ الذي يناسبك",
    },
  },
  service_type: {
    AR: "نوع الخدمة",
    EN: "Service Type",
    placeholder: {
      EN: "Select Service Type",
      AR: "اختر نوع الخدمة",
    },
  },
  help: {
    AR: "هل تحتاج الى مساعدة؟",
    EN: "Anything else we can help with?",
    placeholder: {
      EN: "Add comment",
      AR: "أضف تعليقًا",
    },
  },
  continue: {
    AR: "تأكيد الحجز",
    EN: "Confirm Booking",
    placeholder: {
      EN: "",
      AR: "",
    },
  },
  registration_message: {
    AR: "شكل العينة : 19880AY",
    EN: "Sample format : 19880AY",
    placeholder: {
      EN: "",
      AR: "",
    },
  },
  others: {
    AR: "OTHERS",
    EN: "OTHERS",
  },
};

export const notesContent = {
  EN: (
    <>
      <span className="text-[red]">Please note :</span> By providing your
      contact information, you agree that we may use your data to contact you
      with future marketing and promotional campaigns. We value your privacy and
      will not share your information with third parties without your consent.
    </>
  ),
  AR: "ملاحظة هامة: من خلال تقديم معلومات الاتصال الخاصة بك، فإنك توافق على أنه يجوز لنا استخدام بياناتك للتواصل معك بشأن الحملات التسويقية والترويجية المستقبلية. نحن نقدر خصوصيتك ولن نشارك معلوماتك مع أطراف ثالثة دون موافقتك."
};
