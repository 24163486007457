export const SuccessContent = {
  title: {
    EN: "Your service request has been successfully submitted.",
    AR: "تم تسليم طلب الصيانة الخاص بك بنجاح.",
  },
  sub_title: {
    EN: "Our CRM team will contact you shortly to confirm your booking.",
    AR: "سيقوم فريق مركز خدمة العملاء بالتواصل معك قريبًا لتأكيد حجزك.",
  },
  sub_title2: {
    EN:
      "For any further assistance or advice, please contact our Customer Service Team",
    AR:
      "إن كنت بحاجة إلى المساعدة، لا تتردد في التواصل مع فريق خدمة العملاء على"
  }
};
