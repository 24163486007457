import { format } from "date-fns";

export const title = {
  EN: "Booking Confirmation",
  AR: " تأكيد الحجز",
};

export const content = ({
  name,
  model,
  registration_number,
  service_type,
  service_location,
  service_date,
  language,
}) => {
  const dear = "الفاضل",
    delighted = "،يسعدنا تأكيد موعدك لخدمة سيارتك مع شركةالوطية للسيارات",
    forText = "في",
    onText = "تاريخ";

  return {
    EN: (
      <p className="text-xl md:text-2xl mt-4 mb-2 sm:w-[56%] w-[100%]">
        Dear <span className="text-[#FEC722] font-semibold">{name}</span>, we
        are delighted to advise your service booking with Sayatri for your
        vehicle{" "}
        <span className="text-[#FEC722] font-semibold">
          {model} ( {registration_number})
        </span>{" "}
        for {service_type}
        {/* Service at <b>{service_location} </b> on{" "}
        <b>{service_date} </b> is confirmed. */}
        <p className="mt-4 text-xl md:text-2xl mb-2">
          Service at {service_location || ""} on{" "}
          <span className="text-[#FEC722] font-semibold">
            {service_date}{" "}
          </span>
          is confirmed
        </p>
      </p>
    ),
    AR: (
      <p className="text-xl md:text-2xl mt-4 mb-2 sm:w-[56%] w-[100%]">
        {dear} {name} {delighted} <br />
        {model} ({registration_number}) {forText} {service_type} <br />{" "}
        {forText} {service_location} {onText}{" "}
        {service_date && format(new Date(service_date), "dd-MMM-yyyy h:mm a")}{" "}
      </p>
    ),
  }[language];
};

export const details = ({ rep_name, rep_gsm, language }) => {
  const topText = "استشاري الخدمة الخاص بك للوصول الامن هو",
    repName = "الاسم",
    repGsm = "رقم الهاتف النقال",
    bottomText = "يرجى الاتصال بهذا الرقم عند وصولك مركز الخدمة";

  return {
    EN: (
      <p className="mt-4 text-xl md:text-2xl">
        Your designated Safe Check-In representative is{" "}
        <p className="mt-4 text-xl md:text-2xl">
          Name:<span className="font-semibold"> {rep_name}</span>
        </p>
        <p className="mt-4 text-xl md:text-2xl">
          GSM No: <b style={{ fontFamily: "Montserrat-Bold" }}>{rep_gsm}</b>
        </p>{" "}
        Please call this number on your arrival
      </p>
    ),
    AR: (
      <p className="mt-4 text-xl md:text-2xl">
        {topText} <br /> {repName} {rep_name} <br /> {repGsm} {rep_gsm}
        <br />
        {bottomText}
      </p>
    ),
  }[language];
};
